import React from 'react';
import SEO from "../../components/seo"
import Layout from "../../components/layout"

import Grid from '@material-ui/core/Grid';
import Mission from '../../components/Mission'
import ServicesMenu from '../../components/ServicesMenu'
import SliderBootstrapStatic from '../../components/SliderBootstrapStatic';

export default function OurServices(){

    return(
        <Layout>
            <SEO 
                title="Our Services"
                description="Savante Consulting Limited will partner clients from conception of their projects and provide information that will aid decision through market study and clinical research."
            />
            <br /><br />
            <SliderBootstrapStatic title="Our Services" />

            <div className="container">
                <br />
                <Grid container spacing={3}>
                        <Grid item xs={12} md={8} lg={8}>
                            <ServicesMenu />
                            <h5>Our Services</h5>
                            <p className="pr-20 justify" style={{textAlign: 'justify'}}>
                            Savante Consulting Limited will partner clients from conception of their projects and provide information that will aid decision through market study and clinical research. Services include Product Registration and Regulatory support, including Pharmacovigilance through the entire life cycle of the product.​
                            </p>
                            <p className="pr-20 justify" style={{textAlign: 'justify'}}>
                            Note; Retain the Mission, and the Vision
                            </p>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>  
                                <Mission />
                        </Grid>
                    </Grid>
                    <br /> <br />
            </div>
        </Layout>
    );
}